body {
  margin: 0;

}

.rz_bg{
  margin: 0;
  width: 50%;
  height: 100vh;
  position: absolute;
  z-index: 0;
  display: block;
  margin-left: 25%;
  /* margin-right: auto; */
}
@media screen and (max-width: 900px) {
  .rz_bg{
    width: 100vw;
    margin-left: 0;
  }
  
}